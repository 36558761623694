<template>
  <div>
    <!-- 介绍 -->
    <product-brief
      :height="productBrief && productBrief.height"
      :title="productBrief && productBrief.title"
      :memo="productBrief && productBrief.memo"
      :img-src="productBrief && productBrief.imgSrc"
    />

    <!-- 找回密码 -->
    <div class="container">
      <div class="findPwd">
        <!-- 步骤条 -->
        <steps :current="currentStep" />

        <template v-if="currentStep != 4">
          <!-- 找回密码表单 -->
          <el-form :model="form" :rules="rules" status-icon ref="form">
            <!-- 第一步 -->
            <div v-show="currentStep == 1">
              <el-form-item prop="mobile" label="请填写要找回密码的手机号">
                <el-input
                  ref="mobileInput"
                  v-model.trim="form.mobile"
                  placeholder="133xxxxxxx"
                ></el-input>
              </el-form-item>
            </div>

            <!-- 第二步 -->
            <div v-show="currentStep == 2">
              <p class="code-title">您可以通过安全手机进行帐号修复</p>
              <p class="code-title">
                点击获取验证码,短信将发送至安全手机{{ form.mobile }}
              </p>

              <el-form-item prop="code">
                <div class="getCode">
                  <el-input
                    v-model="form.code"
                    placeholder="短信验证码"
                    clearable
                  ></el-input>
                  <el-button
                    plain
                    :disabled="codeSet.waitSend"
                    @click="sendCode"
                    >{{ codeSet.btnText }}</el-button
                  >
                </div>
              </el-form-item>
            </div>

            <!-- 第三步 -->
            <div v-show="currentStep == 3">
              <el-form-item prop="password" label="请输入新密码">
                <el-input
                  v-model="form.password"
                  placeholder="请输入新密码"
                  show-password
                ></el-input>
              </el-form-item>

              <el-form-item prop="checkPass" label="确认密码">
                <el-input
                  v-model="form.checkPass"
                  placeholder="再次输入密码"
                  show-password
                ></el-input>
              </el-form-item>
            </div>
          </el-form>

          <!-- 按钮 -->
          <div class="btns" v-show="currentStep != 4">
            <el-button plain size="mini" v-show="currentStep == 1"
              >取消</el-button
            >
            <el-button
              plain
              size="mini"
              v-show="currentStep != 1"
              @click="currentStep--"
              >上一步</el-button
            >
            <el-button size="mini" type="primary" @click="nextBtn"
              >下一步</el-button
            >
          </div>
        </template>

        <!-- 重置成功 -->
        <reset-success text="重置密码成功" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import steps from "./components/steps";
import resetSuccess from "./components/reset-success";
import {
  checkMobile,
  getVerifyCode,
  checkVerifyCode,
  resetPass,
} from "@/api/system";

export default {
  components: {
    steps,
    resetSuccess,
  },
  data() {
    var mobilePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        checkMobile(this.form.mobile)
          .then((res) => {
            res.data.state == "WAITING"
              ? callback(new Error("手机号审核中"))
              : callback();
          })
          .catch((err) => {
            err.response.status == 404
              ? callback(new Error("手机号不存在"))
              : callback();
          });
      }
    }; // 确认手机号存在

    var codePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        checkVerifyCode({
          mobile: this.form.mobile,
          captcha: this.form.code,
          type: "FORGOT_PASSWORD",
        })
          .then((res) => {
            callback();
          })
          .catch((err) => {
            callback(new Error("验证码错误"));
          });
      }
    }; // 确认验证码

    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      productBrief: {
        title: "登录遇到问题?",
        height: "180",
        memo: [],
        imgSrc: "https://s1.ax1x.com/2020/07/27/aPrmM8.png",
      }, // 介绍组件数据
      currentStep: 1, // 当前步骤
      form: {}, // 表单
      rules: {
        mobile: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
          {
            pattern: /^(?:(?:\+|00)86)?1\d{10}$/,
            message: "请输入正确的手机号码",
            trigger: ["blur", "change"],
          },
          {
            validator: mobilePass,
            trigger: "none",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: ["blur", "change"],
          },
          {
            validator: codePass,
            trigger: "none",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 8,
            max: 20,
            message: "长度在 8 到 20 个字符",
            trigger: "change",
          },
        ],
        checkPass: [
          {
            required: true,
            message: "请再次输入密码",
            trigger: "blur",
          },
          {
            validator: validatePass2,
            trigger: "change",
          },
        ],
      }, // 校验规则
      codeSet: {
        waitSend: false, // 等待再次发送
        btnText: "发送验证码",
      }, // 验证码设置
    };
  },
  created() {},
  methods: {
    sendCode() {
      let num = 60;
      this.codeSet.waitSend = true;
      getVerifyCode({
        mobile: this.form.mobile,
        type: "FORGOT_PASSWORD",
      });
      let timer = setInterval(() => {
        if (num >= 1) {
          this.codeSet.btnText = `重新发送(${num--}s)`;
        } else {
          clearInterval(timer);
          this.codeSet.waitSend = false;
          this.codeSet.btnText = `发送验证码`;
        }
      }, 1000);
    }, // 发送验证码
    nextBtn() {
      let operations = {
        1: () => {
          this.$refs.form.validateField("mobile", (err) => {
            err ? "" : this.currentStep++;
          });
        }, // 第一步验证
        2: () => {
          this.$refs.form.validateField("code", (err) => {
            err ? "" : this.currentStep++;
          });
        }, // 第二步验证
        3: () => {
          this.$refs.form.validateField("checkPass", (err) => {
            if (!err) {
              resetPass(this.form)
                .then((res) => {
                  this.currentStep++;
                })
                .catch((err) => {
                  this.$message.error(err.response.data);
                });
            }
          });
        }, // 第三步验证
      };

      operations[this.currentStep]();
    }, // 下一步操作
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;

  .findPwd {
    width: 1000px;
    min-height: 360px;
    padding: 40px;
    border: 1px solid var(--border-color);

    .el-form {
      width: 330px;
      margin-top: 40px;

      .code-title {
        font-size: 14px;
        text-align: left;
        line-height: 28px;
      }

      .getCode {
        display: flex;

        button {
          margin-left: 10px;
          border-color: var(--button-color_blue);
          color: var(--button-color_blue);

          &.is-disabled {
            min-width: 130px;
            border-color: #dcdfe6;
            color: var(--sub-title_gray);
          }
        }
      }
    }

    .btns {
      width: 320px;
      text-align: left;
      margin-top: 80px;

      button {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
}
</style>
