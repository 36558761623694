<template>
  <div class="reset-success">
    <p><svg-icon class="icon" icon-class="rf-succeed" /> 重置密码成功!</p>

    <el-button
      type="primary"
      size="medium"
      @click="$store.commit('changeShowLogin', true)"
      >马上登录</el-button
    >
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.reset-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    display: flex;
    align-items: center;
    height: 200px;
    font-size: 18px;

    .icon {
      font-size: 32px;
      color: #5cc871;
      margin-right: 10px;
    }
  }
}
</style>
