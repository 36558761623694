<template>
  <div class="steps">
    <div
      class="step"
      :class="[current >= index + 1 ? 'current' : '']"
      :style="{ zIndex: steps.length - index }"
      v-for="(item, index) in steps"
      :key="index"
    >
      {{ index + 1 + "、" + item }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    current: {
      type: [String, Number],
      default: 1,
    }, // 当前步骤
    steps: {
      type: Array,
      default() {
        return ["验证帐号", "手机验证", "修改密码", "设置成功"];
      },
    }, // 步骤条
  },
};
</script>

<style lang="less" scoped>
.steps {
  display: flex;

  .step {
    position: relative;
    width: 180px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
    background-color: #e8e8e8;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 0;
      border: 20px solid;
      border-color: transparent transparent transparent #fff;
    }

    &::after {
      left: auto;
      right: -40px;
      border-left-color: #e8e8e8;
    }

    &:first-child {
      background-color: #4091f7;

      &::before,
      &::after {
        border-left-color: #4091f7;
      }
    }

    &.current {
      background-color: #4091f7;

      &::after {
        border-left-color: #4091f7;
      }
    }
  }
}
</style>
